import { styled } from "@woba/ds-web";

export const DropdownHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "$space-04 $space-02",
  cursor: "unset",
  borderBottomStyle: "solid",
  borderWidth: "$border-01",
  borderColor: "$neutral-300",
});

export const DropdownFooter = styled("div", {
  display: "flex",
  alignItems: "center",

  "& > *": {
    borderBottom: "none !important",
    width: "100%",
    justifyContent: "center",
  },

  "& > *:last-child": {
    borderLeftStyle: "solid",
    borderWidth: "$border-01",
    borderColor: "$neutral-300",
  },
});

export const Anchor = styled("a", {
  textDecoration: "none",
  color: "inherit",
});
