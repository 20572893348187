import {
  Dropdown,
  DropdownItem,
} from "@/components/ds-components/inputs/dropdown";
import { FunctionComponent } from "react";
import { useAuth } from "../../context/auth";

import { ReactComponent as UserProfileIcon } from "@/assets/icons/auth-dropdown/user-profile.svg";
import { ReactComponent as BookingsIcon } from "@/assets/icons/auth-dropdown/bookings.svg";
import { ReactComponent as MyOfficeIcon } from "@/assets/icons/auth-dropdown/myoffice.svg";
import { ReactComponent as MeetingRoomIcon } from "@/assets/icons/auth-dropdown/meeting-room.svg";
import { ReactComponent as SettingsIcon } from "@/assets/icons/auth-dropdown/settings.svg";
import { ReactComponent as LogoutIcon } from "@/assets/icons/auth-dropdown/logout.svg";
import { ReactComponent as WobaLogo } from "@/assets/woba.svg";
// @TODO substituir quando tiver uma lib de ícones

import * as S from "./auth-dropdown.styles";
import { useTranslation } from "react-i18next";

export const AuthDropdown: FunctionComponent = () => {
  const [translate] = useTranslation(["core"]);
  const { userInfo, signOut } = useAuth();

  return (
    <Dropdown
      variant="secondary"
      value={translate<string>("user-menu-hello", {
        username: userInfo.user.first_name,
      })}
    >
      <S.DropdownHeader>
        <h4>
          {userInfo.user.first_name} {userInfo.user.last_name}
        </h4>
      </S.DropdownHeader>
      <DropdownItem icon={<UserProfileIcon />} asChild>
        <S.Anchor href={`${import.meta.env.VITE_PORTAL_URL}/profile/me`}>
          {translate("user-menu-profile-link")}
        </S.Anchor>
      </DropdownItem>
      <DropdownItem icon={<BookingsIcon />} asChild>
        <S.Anchor href={`${import.meta.env.VITE_PORTAL_URL}/profile/bookings`}>
          {translate("user-menu-bookings-link")}
        </S.Anchor>
      </DropdownItem>
      <DropdownItem icon={<MyOfficeIcon />} asChild>
        <S.Anchor
          href={`${import.meta.env.VITE_PORTAL_URL}/profile/my-coworking`}
        >
          {translate("user-menu-coworking-link")}
        </S.Anchor>
      </DropdownItem>
      <DropdownItem icon={<MeetingRoomIcon />} asChild>
        <S.Anchor href={`${import.meta.env.VITE_LISTING_URL}?product=mr`}>
          {translate("user-menu-meetingrooms-link")}
        </S.Anchor>
      </DropdownItem>
      <DropdownItem icon={<WobaLogo width={24} />} asChild>
        <S.Anchor href={import.meta.env.VITE_PORTAL_URL}>
          {translate("user-menu-home-link")}
        </S.Anchor>
      </DropdownItem>

      <S.DropdownFooter>
        <DropdownItem icon={<SettingsIcon />} asChild>
          <S.Anchor
            href={`${import.meta.env.VITE_PORTAL_URL}/profile/configuration`}
          >
            {translate("user-menu-settings-link")}
          </S.Anchor>
        </DropdownItem>
        <DropdownItem
          label={translate<string>("user-menu-logout")}
          icon={<LogoutIcon />}
          onSelect={() => {
            signOut();
          }}
        />
      </S.DropdownFooter>
    </Dropdown>
  );
};
