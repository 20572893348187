import { styled } from "@woba/ds-web";

export const Wrapper = styled("div", {
  overflow: "hidden",
  position: "relative",
  height: "100vh",
  maxHeight: "100vh",
});

export const ContainerContent = styled("div", {
  display: "flex",
  overflow: "hidden",

  "@sm": {
    flexDirection: "column",
  },
});

export const MainContainer = styled("main", {
  flex: 1,
  padding: "$space-05",
  overflow: "auto",
});

export const ContentHeader = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

export const ContainerMyOffices = styled("div", {
  display: "flex",
  alignItems: "center",

  span: {
    color: "$secondary-900",
    marginLeft: "$space-04",
    borderWidth: "$border-01",
    borderLeftStyle: "solid",
    borderColor: "$secondary-900",
    padding: "0 $space-03",
  },
});

export const HeaderWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
});
