import { styled } from "@woba/ds-web";

export const Wrapper = styled("div", {
  borderWidth: "$border-01",
  borderColor: "$neutral-400",
  borderTopStyle: "solid",
  display: "flex",
  userSelect: "none",
  overflowX: "auto",
  backgroundColor: "white",
});
