import { FunctionComponent } from "react";
import { TabBarItemProps } from "./tab-bar-item.types";

import * as S from "./tab-bar-item.styles";
import { useVerifyRouter } from "@/hooks/use-verify-router";

export const TabBarItem: FunctionComponent<TabBarItemProps> = ({
  link,
  icon,
  label,
  onChange,
}) => {
  const { activeItem } = useVerifyRouter({ link });

  const handleClickLink = () => {
    onChange?.(link);
  };

  return (
    <S.Tab key={label} status={activeItem ? "active" : undefined}>
      <S.NavLink to={link} onClick={handleClickLink}>
        <S.IconWrapper>{icon}</S.IconWrapper>
        <S.LabelWrapper>{label}</S.LabelWrapper>
      </S.NavLink>
    </S.Tab>
  );
};
