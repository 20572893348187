import { createContext, FunctionComponent, useContext, useState } from "react";
import {
  ErrorBoundaryContextProps,
  ErrorBoundaryProviderProps,
} from "./error-boundary-context.types";

export const ErrorContext = createContext<ErrorBoundaryContextProps>(
  {} as ErrorBoundaryContextProps,
);

export const ErrorContextProvider: FunctionComponent<
  ErrorBoundaryProviderProps
> = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const updateError = (value: boolean) => {
    setHasError(value);
  };

  return (
    <ErrorContext.Provider value={{ hasError, updateError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorBoundary = () => useContext(ErrorContext);
