import { ROUTE, ROUTES } from "@/constants/routes";

export const EVENT_CATEGORIES = {
  MENU: "Menu lateral",
  DASHBOARD: "Dashboard",
  MEMBERS: "Membros",
  CONTRACTS: "Contratos",
  INSIGHTS: "Utilização",
  VIEW: "View",
  SCROLL: "Scroll",
  SUBSCRIBERS: "Membros Elegíveis",
  PACKAGES: "Pacotes",
  PRIVATE_OFFICES: "Escritórios Privativos",
  PAYMENTS: "Pagamentos",
};

export const EVENTS = {
  MENU_COMPANYMEMBERS_CLICK: "menu_companymembers_click",
  MENU_CONTRACTS_CLICK: "menu_contracts_click",
  MENU_VISITSCHEDULES_CLICK: "menu_visitschedules_click",
  MENU_DASHBOARD_CLICK: "menu_dashboard",
  MENU_UTILIZATION_CLICK: "menu_utilization_click",
  MENU_PRIVATE_OFFICES_CLICK: "menu_private_offices_click",
  DASHBOARD_CHANGE_BUDGET: "budget_set",
  DASHBOARD_QUALITY_REVIEW_CLICK: "dashboard_quality_review_click",
  DASHBOARD_RESERVATION_DAILY_CLICK: "dashboard_reservation_daily_click_zoho",
  DASHBOARD_GRAPHIC_DAILY_CLICK: "dashboard_graphic_daily_click_zoho",
  DASHBOARD_FILTER_RANGE: "dashboard_filter_range",
  DASHBOARD_FILTER_MONTH: "dashboard_filter_month",
  DASHBOARD_FILTER_GROUP: "dashboard_filter_group",
  DASHBOARD_FILTER_CITY: "dashboard_filter_city",
  DASHBOARD_FILTER_NEIGHBORHOOD: "dashboard_filter_neighborhood",
  DASHBOARD_SWITCH_CREDITS: "dashboard_switch_credits",
  DASHBOARD_SWITCH_VALUE: "dashboard_switch_value",
  DASHBOARD_FILTER_MEMBERS: "dashboard_filter_members",
  REVIEWS_FILTER_MONTH: "reviews_filter_month",
  REVIEWS_FILTER_RANGE: "reviews_filter_range",
  REVIEWS_FILTER_SPACE_TYPE: "reviews_filter_space_type",
  REVIEWS_FILTER_CITY: "reviews_filter_city",
  MEMBER_FILTER_SEARCH: "members_filter_search",
  MEMBER_FILTER_GROUP: "members_filter_group",
  MEMBER_EXPORT: "member_export",
  MEMBER_SUBSCRIBER_EXPORT: "member_export",
  MEMBER_GROUP_EXPORT: "member_export",
  MEMBER_ADD: "member_add",
  MEMBER_COPY_LINK: "member_copy_link",
  MEMBER_REMOVE: "member_remove",
  MEMBER_REMOVE_BATCH: "member_remove_batch",
  MEMBER_CHANGE_GROUP_BATCH: "member_change_group_batch",
  SUBSCRIBERS_REMOVE_BATCH: "subscriber_remove_batch",
  MEMBER_CHANGE_PLAN_BATCH: "member_change_plan_batch",
  MEMBER_CHANGE_GROUP: "member_change_group",
  MEMBER_CHANGE_PLAN: "member_change_plan",
  MEMBER_SEARCH: "member_search",
  MEMBERS_IMPORT_DONWLOAD_INVALID: "members_import_download_invalid",
  MEMBERS_IMPORT_DONWLOAD_REMOVE: "members_import_download_remove",
  MEMBERS_IMPORT_DOWNLOAD_MODEL: "members_import_download_model",
  MEMBERS_SWITCH: "members_switch",
  SUBSCRIBERS_SWITCH: "subscribers_switch",
  GROUPS_SWITCH: "groups_switch",
  MEMBERS_EXPORT: "members_export",
  MEMBERS_GROUP_CREATE: "members_group_create",
  PAGE_CONTRACTS_SCROLL: "page_contracts_scroll",
  CONTRACTS_SELECT_CONTRACT: "contracts_select_contract",
  CONTRACTS_FILTER_STATUS: "contracts_filter_status",
  INSIGHTS_FILTER_RANGE: "use_filter_range",
  INSIGHTS_USE_FILTER_MONTH_TODAY: "use_filter_today",
  INSIGHTS_USE_FILTER_MONTH_YESTERDAY: "use_filter_yesterday",
  INSIGHTS_USE_FILTER_MONTH_WEEK: "use_filter_week",
  INSIGHTS_USE_FILTER_MONTH_CURRENT_MONTH: "use_filter_current_month",
  INSIGHTS_USE_FILTER_MONTH_LAST_MONTH: "use_filter_last_month",
  INSIGHTS_USE_FILTER_MONTH_Q: "use_filter_q",
  INSIGHTS_USE_FILTER_MONTH_LAST_Q: "use_filter_last_q",
  INSIGHTS_USE_FILTER_MONTH_YEAR: "use_filter_year",
  INSIGHTS_USE_FILTER_NEIGHBORHOOD: "use_filter_neighborhood",
  INSIGHTS_USE_FILTER_MEMBERS: "use_filter_members",
  INSIGHTS_USE_FILTER_SPACE: "use_filter_space",
  INSIGHTS_USE_FILTER_STATUS: "use_filter_status",
  INSIGHTS_USE_FILTER_CITY: "use_filter_city",
  INSIGHTS_USE_FILTER_SPACE_TYPE: "use_filter_type",
  INSIGHTS_USE_FILTER_GROUP: "use_filter_groups",
  INSIGHTS_USE_RANKING_VIEW: "use_ranking_view",
  INSIGHTS_RANKING_CITY: "use_ranking_city",
  INSIGHTS_RANKING_SPACE: "use_ranking_space",
  INSIGHTS_RANKING_MEMBER: "use_ranking_member",
  INSIGHTS_RANKING_GROUPS: "use_ranking_groups",
  INSIGHTS_FILTER_CLICK: "use_members_filter_click_zoho",
  INSIGHTS_EXPORT: "insights_export",
  INSIGHTS_FILTERS_URL: "insights_filters_url",
  PAGE_MEMBERS_VIEW: "page_companymembers_view",
  PAGE_CONTRACTS_VIEW: "page_contracts_view",
  PAGE_SCHEDULES_VIEW: "page_visitsschedule_view",
  PAGE_DASHBOARD_VIEW: "page_dashboard_view",
  PAGE_INSIGHTS_VIEW: "page_utilization_view",
  PAGE_REVIEWS_SCROLL: "page_reviews_scroll",
  PAGE_INSIGHTS_SCROLL: "page_utilization_scroll",
  PAGE_MEMBERS_SCROLL: "page_companymembers_scroll",
  PAGE_PRIVATE_OFFICES_VIEW: "page_private_offices",
  PAGE_PACKAGES_VIEW: "page_packages_view",
  PAGE_PAYMENTS_VIEW: "page_payments_view",
  DASHBOARD_MINIMUM_CONSUMPTION: "dashboard_minimum_consumption",
  CREDITS_PACKAGES_CLICK: "credits_package_click",
  SIDEBAR_OFFER_PRIVATEOFFICE: "offer_privateoffice",
  MEMBERS_IMPORT_INIT: "members_import_init",
  MEMBERS_IMPORT_DOWNLOAD_TEMPLATE: "members_import_download_template",
  MEMBERS_IMPORT_ADVANCED: "members_import_advanced",
  MEMBERS_IMPORT_FILE: "members_import_file",
  MEMBERS_IMPORT_TYPE_OPTION: "members_import_type_option",
  MEMBERS_IMPORT_TABS: "members_import_tabs",
  MEMBERS_IMPORT_DOWNLOAD_INVALID: "members_import_download_invalid",
  MEMBERS_IMPORT_CLICK: "members_import_click",
  MEMBERS_IMPORT_EXIT: "members_import_exit",
  MEMBERS_IMPORT_COMPLETE: "members_import_complete",
  MEMBERS_IMPORT_RETURN: "members_import_return",
  PRIVATE_OFFICES_OFFER_CLICK: "offer_privateoffice",
  PRIVATE_OFFICES_TAB_OFFICES_CLICK: "po_myoffices",
  PRIVATE_OFFICES_TAB_VISITS_CLICK: "po_visits",
  PRIVATE_OFFICES_SEARCH: "po_search",
  PRIVATE_OFFICES_FILTER_CLEAR_CLICK: "po_clear_filters",
  PRIVATE_OFFICES_FILTER_MONTH_CLICK: "po_filter_month",
  PRIVATE_OFFICES_FILTER_CITY_CLICK: "po_filter_city",
  PRIVATE_OFFICES_FILTER_NEIGHBORHOOD_CLICK: "po_filter_neighborhood",
  PRIVATE_OFFICES_FILTER_COWORKING_CLICK: "po_filter_coworking",
  PRIVATE_OFFICES_FILTER_STATUS_CLICK: "po_filter_reservation_status",
  PACKAGES_ACTION: "packages_action",
  PACKAGES_EXPORT: "packages_export",
  PAGE_PACKAGES_DETAILS: "page_packages_detail",
  PAGE_GROUP_DETAILS: "page_group_detail",
  PAYMENTS_DOWNLOAD_INVOICE: "download_fatura",
  PAYMENTS_ACCESS_INSIGHTS: "acessar_reservas",
  PAYMENTS_COPY_BARCODE: "copy_code",
};

export const EVENT_BY_ROUTE: { [key in ROUTE]?: string } = {
  [ROUTES.MEMBERS]: EVENTS.MENU_COMPANYMEMBERS_CLICK,
  [ROUTES.CONTRACTS]: EVENTS.MENU_CONTRACTS_CLICK,
  [ROUTES.DASHBOARD]: EVENTS.MENU_DASHBOARD_CLICK,
  [ROUTES.INSIGHTS]: EVENTS.MENU_UTILIZATION_CLICK,
  [ROUTES.PRIVATE_OFFICES]: EVENTS.MENU_PRIVATE_OFFICES_CLICK,
  [ROUTES.SCHEDULINGS]: EVENTS.MENU_VISITSCHEDULES_CLICK,
  [ROUTES.MEMBERS_IMPORT]: EVENTS.MEMBERS_IMPORT_INIT,
};

export type EVENT_FILTER =
  | "FILTER_RANGE"
  | "FILTER_CITY"
  | "TODAY"
  | "YESTERDAY"
  | "CURRENT_WEEK"
  | "CURRENT_MONTH"
  | "LAST_MONTH"
  | "CURRENT_QUARTER"
  | "LAST_QUARTER"
  | "CURRENT_YEAR"
  | "FILTER_GROUP"
  | "FILTER_BOOKING_TYPE"
  | "FILTER_SEARCH"
  | "FILTER_STATUS"
  | "FILTER_NEIGHBORHOOD"
  | "FILTER_MEMBERS"
  | "FILTER_SPACE";

export const EVENTS_FILTER_BY_PAGE: {
  [key in ROUTE]?: { [key in EVENT_FILTER]?: string };
} = {
  [ROUTES.DASHBOARD]: {
    FILTER_RANGE: EVENTS.DASHBOARD_FILTER_RANGE,
    USE_FILTER_TODAY: EVENTS.INSIGHTS_USE_FILTER_MONTH_TODAY,
    USE_FILTER_YESTERDAY: EVENTS.INSIGHTS_USE_FILTER_MONTH_YESTERDAY,
    USE_FILTER_WEEK: EVENTS.INSIGHTS_USE_FILTER_MONTH_WEEK,
    USE_FILTER_CURRENT_MONTH: EVENTS.INSIGHTS_USE_FILTER_MONTH_CURRENT_MONTH,
    USE_FILTER_LAST_MONTH: EVENTS.INSIGHTS_USE_FILTER_MONTH_LAST_MONTH,
    USE_FILTER_Q: EVENTS.INSIGHTS_USE_FILTER_MONTH_Q,
    USE_FILTER_LAST_Q: EVENTS.INSIGHTS_USE_FILTER_MONTH_LAST_Q,
    USE_FILTER_YEAR: EVENTS.INSIGHTS_USE_FILTER_MONTH_YEAR,
    FILTER_CITY: EVENTS.DASHBOARD_FILTER_CITY,
    FILTER_MONTH: EVENTS.DASHBOARD_FILTER_MONTH,
    FILTER_GROUP: EVENTS.DASHBOARD_FILTER_GROUP,
    FILTER_NEIGHBORHOOD: EVENTS.DASHBOARD_FILTER_NEIGHBORHOOD,
    FILTER_MEMBERS: EVENTS.DASHBOARD_FILTER_MEMBERS,
  },
  [ROUTES.REVIEWS]: {
    FILTER_BOOKING_TYPE: EVENTS.REVIEWS_FILTER_SPACE_TYPE,
    FILTER_CITY: EVENTS.REVIEWS_FILTER_CITY,
    FILTER_RANGE: EVENTS.REVIEWS_FILTER_RANGE,
    FILTER_MONTH: EVENTS.REVIEWS_FILTER_MONTH,
  },
  [ROUTES.MEMBERS]: {
    FILTER_SEARCH: EVENTS.MEMBER_FILTER_SEARCH,
    FILTER_GROUP: EVENTS.MEMBER_FILTER_GROUP,
  },
  [ROUTES.CONTRACTS]: {
    FILTER_STATUS: EVENTS.CONTRACTS_FILTER_STATUS,
  },
  [ROUTES.INSIGHTS]: {
    FILTER_RANGE: EVENTS.INSIGHTS_FILTER_RANGE,
    USE_FILTER_TODAY: EVENTS.INSIGHTS_USE_FILTER_MONTH_TODAY,
    USE_FILTER_YESTERDAY: EVENTS.INSIGHTS_USE_FILTER_MONTH_YESTERDAY,
    USE_FILTER_WEEK: EVENTS.INSIGHTS_USE_FILTER_MONTH_WEEK,
    USE_FILTER_CURRENT_MONTH: EVENTS.INSIGHTS_USE_FILTER_MONTH_CURRENT_MONTH,
    USE_FILTER_LAST_MONTH: EVENTS.INSIGHTS_USE_FILTER_MONTH_LAST_MONTH,
    USE_FILTER_Q: EVENTS.INSIGHTS_USE_FILTER_MONTH_Q,
    USE_FILTER_LAST_Q: EVENTS.INSIGHTS_USE_FILTER_MONTH_LAST_Q,
    USE_FILTER_YEAR: EVENTS.INSIGHTS_USE_FILTER_MONTH_YEAR,
    FILTER_BOOKING_TYPE: EVENTS.INSIGHTS_USE_FILTER_SPACE_TYPE,
    FILTER_GROUP: EVENTS.INSIGHTS_USE_FILTER_GROUP,
    FILTER_NEIGHBORHOOD: EVENTS.INSIGHTS_USE_FILTER_NEIGHBORHOOD,
    FILTER_MEMBERS: EVENTS.INSIGHTS_USE_FILTER_MEMBERS,
    FILTER_SPACE: EVENTS.INSIGHTS_USE_FILTER_SPACE,
    FILTER_STATUS: EVENTS.INSIGHTS_USE_FILTER_STATUS,
    FILTER_CITY: EVENTS.INSIGHTS_USE_FILTER_CITY,
  },
};
