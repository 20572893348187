import { useLocation } from "react-router-dom";
import { UseVerifyRouterProps } from "./use-verify-router.types";

export const useVerifyRouter = ({ link }: UseVerifyRouterProps) => {
  const { pathname } = useLocation();

  const activeItem = pathname.includes(link);

  return { activeItem };
};
