import { DEMO_ROUTES } from "@/constants/routes";
import { AxiosInstance } from "axios";
import { getDemoResponseByUrl } from "../utils/demo-response-by-url";
import { parseDemoRequestUrl } from "../utils/parse-demo-request-url";

type DemoReponse<T> = {
  data: T;
};

export const DemoRequestInterceptor = (api: AxiosInstance) => {
  api.interceptors.request.use(
    (config) => {
      if (window.location.pathname.startsWith(DEMO_ROUTES.INDEX)) {
        const route = parseDemoRequestUrl(config.url!, config.method!);

        const demo: DemoReponse<any> = {
          data: getDemoResponseByUrl(route),
        };

        return Promise.reject(demo);
      }

      return config;
    },
    (error) => Promise.reject(error),
  );
};

export const DemoResponseInterceptor = (api: AxiosInstance) => {
  api.interceptors.response.use(
    (response) => response,
    (demo) => Promise.resolve(demo),
  );
};
