import { styled } from "@woba/ds-web";

export const Wrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  fontFamily: "FK Grotesk",
  fontWeight: 700,
  fontSize: "$sm",
  padding: "$space-03",
  backgroundColor: "$warning-200",
  color: "$neutral-900",
  position: "sticky",
});
