import { FunctionComponent, Suspense, useCallback, useState } from "react";
import { PageContainerProps } from "./page-container.types";

import * as S from "./page-container.styles";

import wobaLogo from "@/assets/woba.svg";
import { ErrorBoundary } from "@/components/error-boundary";
import { VisibleBreakpoint } from "@/components/visible-breakpoint";
import { NO_SIDEBAR } from "@/constants/no-sidebar";
import { AnalyticsProvider } from "@/features/analytics";
import { AuthProvider } from "@/features/authentication";
import { AuthDropdown } from "@/features/authentication/layouts/auth-dropdown";
import { DemoWarningBanner } from "@/features/demo";
import { Header } from "@woba/ds-web";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ModalErrorRequest } from "../modal-error-request";
import { SidebarNavigation, TabBarNavigation } from "./shared";

export const PageContainer: FunctionComponent<PageContainerProps> = ({
  children,
}) => {
  const [translate] = useTranslation(["core"]);
  const [headerHeight, setHeaderHeight] = useState<number>();

  const headerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const { pathname } = useLocation();
  const verifyPathSidebar = NO_SIDEBAR.includes(pathname);

  return (
    <S.Wrapper>
      <AuthProvider>
        <AnalyticsProvider>
          <ErrorBoundary fallback={<ModalErrorRequest />}>
            <Header
              ref={headerRef}
              color="complementary01-500"
              data-test-id="header"
            >
              <S.ContentHeader>
                <S.ContainerMyOffices>
                  <img src={wobaLogo} />
                  <span>{translate("app-name")}</span>
                </S.ContainerMyOffices>
              </S.ContentHeader>
              <ErrorBoundary fallback={<></>}>
                <Suspense fallback={<></>}>
                  <AuthDropdown />
                </Suspense>
              </ErrorBoundary>
            </Header>
            <DemoWarningBanner />
            <S.ContainerContent
              css={{ height: `calc(100vh - ${headerHeight}px)` }}
            >
              {!verifyPathSidebar && (
                <VisibleBreakpoint breakpoint={"sm"} type="min">
                  <SidebarNavigation />
                </VisibleBreakpoint>
              )}

              <S.MainContainer className="!p-0 overflow-x-hidden">
                {children}
              </S.MainContainer>
              <VisibleBreakpoint breakpoint={"sm"} type="max">
                <TabBarNavigation />
              </VisibleBreakpoint>
            </S.ContainerContent>
          </ErrorBoundary>
        </AnalyticsProvider>
      </AuthProvider>
    </S.Wrapper>
  );
};
