// @ts-nocheck

const initialize = () => {
  let head = document.getElementsByTagName("head")[0];

  let script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "hs-script-loader";
  script.async = true;
  script.defer = true;
  script.src = "//js.hs-scripts.com/21669165.js";

  head.appendChild(script);
};

const identify: Identify = (email, token) => {
  window.hsConversationsSettings = {
    loadImmediately: false,
  };

  window.hsConversationsSettings = {
    loadImmediately: false,
    identificationEmail: email,
    identificationToken: token,
  };

  window.HubSpotConversations.widget.load();
};

const handleOpenChat = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.open();
  } else {
    console.log("HubSpot Conversations is not initialized");
  }
};

export const hubspot = { initialize, handleOpenChat, identify };
