import { FunctionComponent } from "react";

import * as S from "./navigation-item.styles";
import { NavigationItemProps } from "./navigation-item.types";

import { useVerifyRouter } from "@/hooks/use-verify-router";
import { useActionsTrack } from "@/features/analytics";

export const NavigationItem: FunctionComponent<NavigationItemProps> = ({
  name,
  link,
  icon,
  tracker,
  onChange,
}) => {
  const { activeItem } = useVerifyRouter({ link });
  const { onActionClick } = useActionsTrack();

  const handleClickLink = () => {
    if (tracker) {
      onActionClick(tracker, "MENU");
    }
    onChange?.(link);
  };

  return (
    <S.NavItem>
      <S.NavLink
        to={link}
        onClick={handleClickLink}
        status={activeItem ? "active" : undefined}
      >
        <S.NavIcon>{icon}</S.NavIcon>
        <S.NavText>{name}</S.NavText>
      </S.NavLink>
    </S.NavItem>
  );
};
