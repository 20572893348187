import {
  DemoRequestInterceptor,
  DemoResponseInterceptor,
} from "@/features/demo";
import { BLOCKED_LEAGUES } from "@/hooks/use-feature-flag/shared/use-league-not-permission";
import { isEnabledByEnvAndLeague } from "@/hooks/use-feature-flag/utils";
import { cookieManager } from "@/utils/cookie-manager";
import { InterceptorStatusCode } from "@/utils/interceptor-status-code";
import axios from "axios";

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

const actualDate = new Date();
const expirationDate = new Date(
  new Date(actualDate).setMonth(actualDate.getMonth() + 1),
);

api.interceptors.request.use((config) => {
  const userToken = cookieManager.get<string>({ cookieName: "u_token" });
  const urlParams = new URLSearchParams(window.location.search);

  const queryToken = urlParams.get("auth_token");

  if (queryToken) {
    cookieManager.set({
      cookieName: "u_token",
      value: queryToken,
      globally: true,
      expirationDate,
    });
  }

  if (!userToken && !queryToken) {
    cookieManager.set({
      cookieName: "last_url_path",
      value: "my-privateoffice",
      globally: true,
      expirationDate,
    });

    window.location.href = `${import.meta.env.VITE_PORTAL_URL}/signin`;

    throw new axios.Cancel("user not logged");
  }
  // @ts-ignore
  config.headers = {
    "Auth-token": userToken ? userToken : queryToken,
    Authorization: `Bearer ${userToken ? userToken : queryToken}`,
  };
  return config;
});

api.interceptors.response.use((response) => {
  const isUserFromBlockedLeague = isEnabledByEnvAndLeague(
    response.data.user?.leagues_id[0],
    BLOCKED_LEAGUES,
  );

  if (isUserFromBlockedLeague) {
    window.location.href = import.meta.env.VITE_PORTAL_URL;
    throw new axios.Cancel("user not authorized");
  }

  return response;
});

InterceptorStatusCode(api);

DemoRequestInterceptor(api);
DemoResponseInterceptor(api);
