export const HEADER_ITEMS_EXPORT = [
  "members-import-instruction-table-body-invalid-reason",
  "members-import-instruction-table-body-registration",
  "members-import-instruction-table-body-name",
  "members-import-instruction-table-body-surname",
  "members-import-instruction-table-body-email",
  "members-import-instruction-table-body-phone",
  "members-import-instruction-table-body-date",
  "members-import-instruction-table-body-zipcode",
  "members-import-instruction-table-body-office",
  "members-import-instruction-table-body-cost-center",
  "members-import-instruction-table-body-departament",
  "members-import-instruction-table-body-sub-departament",
];
