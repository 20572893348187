import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import {
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  TracerConfig,
  WebTracerProvider,
} from "@opentelemetry/sdk-trace-web";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { Resource } from "@opentelemetry/resources";

const initialize = () => {
  const providerConfig: TracerConfig = {
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: import.meta.env
        .VITE_OTEL_APP_NAME,
    }),
  };
  const provider = new WebTracerProvider(providerConfig);
  import.meta.env.DEV &&
    provider.addSpanProcessor(
      new SimpleSpanProcessor(new ConsoleSpanExporter()),
    );
  provider.register({});
  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-fetch": {},
      }),
    ],
  });
};

export const openTelemetry = { initialize };
