import { getSubscribersApi } from "../../adapters/get-subscribers";

export const useGetTotalSubscribers = ({
  league,
  groupId,
}: {
  groupId?: number;
  league: number;
}) => {
  const { total } = getSubscribersApi({
    league,
    groupId,
  });

  return total;
};
