import { useTranslation } from "react-i18next";

export const Empty = () => {
  const [translate] = useTranslation(["members"]);

  return (
    <div className="h-full flex items-center justify-center flex-col rounded-lg border border-solid border-neutral-200 w-full">
      <h2 className="text-neutral-900 text-4xl font-bold leading-10 tracking-tighter mb-4">
        {translate("members-import-step-3-table-empty-title")}
      </h2>
      <p className="text-neutral-700 text-center font-body text-sm font-normal leading-4 max-w-sm">
        {translate("members-import-step-3-table-empty-description")}
      </p>
    </div>
  );
};
