import { FunctionComponent } from "react";
import * as S from "./sidebar.styles";
import { SidebarProps } from "./sidebar.types";

export const Sidebar: FunctionComponent<SidebarProps> = ({
  children,
  color,
  position,
}) => {
  return (
    <S.SideBarContainer
      position={position}
      css={{ backgroundColor: color && `$${color}` }}
    >
      {children}
    </S.SideBarContainer>
  );
};
