import { styled } from "@woba/ds-web";

export const Wrapper = styled("div", {
  borderRadius: "$sm",
  backgroundColor: "transparent",
  width: "fit-content",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  fontWeight: "500",

  cursor: "pointer",

  padding: "$space-04 $space-05",

  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "$neutral-200",

  transition: "0.2s",
  "&:hover": { background: "$neutral-100" },
  variants: {
    disabled: {
      true: {
        pointerEvents: "none",
        backgroundColor: "$neutral-100",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "transparent",
      },
    },
  },
});

export const Button = styled("button", {
  all: "unset",
  display: "flex",
  alignItems: "center",

  fontSize: "0.875rem",

  "&:hover": {
    color: "$neutral-900",

    p: {
      color: "$neutral-900",
    },
  },

  a: {
    textDecoration: "none",
    fontSize: 14,
    color: "$neutral-700",
    fontWeight: "normal",
  },
  variants: {
    disabled: {
      true: {
        color: "$neutral-500",
      },
    },
  },
});

export const ContentButton = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
