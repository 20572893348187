import { useState, useEffect } from "react";
import { isEnabledByEnvAndLeague } from "../utils";

export const BLOCKED_LEAGUES = JSON.parse(
  import.meta.env.VITE_BLOCKED_LEAGUES,
) as number[];

export const useLeagueNotPermission = (league: number) => {
  const [isLeagueBlocked, setIsLeagueBlocked] = useState<boolean>(false);

  useEffect(() => {
    setIsLeagueBlocked(() => isEnabledByEnvAndLeague(league, BLOCKED_LEAGUES));
  }, [league]);

  return { isLeagueBlocked };
};
