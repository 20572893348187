import wobaLogo from "@/assets/woba.svg";
import { FunctionComponent } from "react";
import { SidebarNavigationProps } from "./sidebar-navigation.types";

import {
  CardTeam,
  Sidebar,
  SidebarNav,
} from "@/components/ds-components/navigation/sidebar";

import { itemReports, navItems } from "@/constants/items-menu";
import { useChangeRouteTrack } from "@/features/analytics";
import { useAuth } from "@/features/authentication";
import { useFeatureFlags } from "@/hooks/use-feature-flag";

export const SidebarNavigation: FunctionComponent<
  SidebarNavigationProps
> = () => {
  const { userInfo } = useAuth();
  const { onChangeRoute } = useChangeRouteTrack();

  const { isReportEnabled } = useFeatureFlags(userInfo);

  return (
    <Sidebar>
      <CardTeam image={wobaLogo} name={userInfo.league.name} />

      <SidebarNav
        items={isReportEnabled ? [...navItems, ...itemReports] : navItems}
        onChange={onChangeRoute}
      />
    </Sidebar>
  );
};
