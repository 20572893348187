import { useApi } from "@/hooks/use-api";
import { GetUsersProps, UsersResponse } from "./get-users.types";

export const getUsersApi = ({
  league,
  page = 1,
  search = "",
  groupId,
  limit,
}: GetUsersProps) => {
  const searchParams = new URLSearchParams();
  searchParams.append("league_id", `${league}`);
  searchParams.append("group_id", `${groupId}`);
  searchParams.append("search", search);
  searchParams.append("limit", `${limit}`);
  searchParams.append("page", `${page}`);

  const { data }: UsersResponse = useApi({
    path: `/v1/users?${searchParams.toString()}`,
    method: "get",
  });

  return {
    data: data._embedded.users,
    total: data.total,
    count: data.count,
  };
};
