import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { HEADER_ITEMS_EXPORT } from "../../../constants/header-export-invalid-members";
import { useActionsTrack } from "@/features/analytics/hooks/actions-track";
import { subscribersErrosReport } from "@/features/members/contexts/import-context/import-context.types";

export const useDownloadImportMembers = () => {
  const [translate] = useTranslation(["members"]);

  const { onActionClick } = useActionsTrack();

  const convertDataUsers = (data: subscribersErrosReport[]) => {
    return data.map((line) => ({
      reason: translate(line.reason),
      registrationId: line.registrationId,
      firstName: line.firstName,
      lastName: line.lastName,
      email: line.email,
      phone: line.phone,
      birthday: line.birthday,
      postalCode: line.postalCode,
      jobRole: line.jobRole,
      group: line.group,
      department: line.department,
      subDepartment: line.subDepartment,
    }));
  };

  function handleExport(data: subscribersErrosReport[], type: string) {
    if (type === "delete") {
      onActionClick("MEMBERS_IMPORT_DONWLOAD_REMOVE", "MEMBERS");
    } else {
      onActionClick("MEMBERS_IMPORT_DONWLOAD_INVALID", "MEMBERS");
    }

    const excelFormatted = convertDataUsers(data);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelFormatted);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Membros");

    const header_format = HEADER_ITEMS_EXPORT.map((item) => translate(item));
    XLSX.utils.sheet_add_aoa(worksheet, [header_format]);

    XLSX.writeFile(workbook, `${type}_members_list.xlsx`, {
      compression: true,
    });
  }
  return { handleExport };
};
