import { ButtonOutline } from "@/components/inputs/button-outline";
import { useActionsTrack } from "@/features/analytics";
import { INSTRUCTION_DATA } from "@/features/members/constants/instruction-data";
import { cn } from "@/utils/utils";
import { AlertCircleIcon } from "@woba/ds-core/icons";
import {
  Table,
  TableBody,
  TableCellBody,
  TableCellHead,
  TableHead,
  TableRow,
} from "@woba/ds-web";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export const InstructionSection = () => {
  const { onActionClick, onActionView } = useActionsTrack();
  const [translate] = useTranslation(["members"]);

  const BulletNumber = ({ children }: {} & PropsWithChildren) => {
    return (
      <div className="bg-complementary-500 min-w-6 w-6 h-6 rounded-full flex items-center justify-center text-xs text-neutral-900">
        {children}
      </div>
    );
  };

  const downloadFile = () => {
    const element = document.createElement("a");
    element.href = "/modelo_de_importacao_de_usuarios.xlsx";
    element.download = "modelo_de_importacao_de_usuarios.xlsx";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleDownloadClick = () => {
    downloadFile();

    onActionClick("MEMBERS_IMPORT_DOWNLOAD_TEMPLATE", "MEMBERS");
  };

  return (
    <div className="pt-6">
      <div className="flex gap-4">
        <div>
          <div className="flex gap-2">
            <BulletNumber>1</BulletNumber>
            <div>
              <h4 className="font-normal text-sm text-neutral-900">
                {translate("members-import-instruction-title-step1")}
              </h4>
              <p className="font-normal text-sm text-neutral-700">
                {translate("members-import-instruction-description-step1")}
              </p>
            </div>
          </div>
          <div className="my-4">
            <ButtonOutline
              onClick={() => handleDownloadClick()}
              icon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.8125 11.9375H3.1875C2.72337 11.9375 2.27825 11.7531 1.95006 11.4249C1.62187 11.0967 1.4375 10.6516 1.4375 10.1875V3.1875C1.4375 2.72337 1.62187 2.27825 1.95006 1.95006C2.27825 1.62187 2.72337 1.4375 3.1875 1.4375H10.1875C10.6516 1.4375 11.0967 1.62187 11.4249 1.95006C11.7531 2.27825 11.9375 2.72337 11.9375 3.1875V5.8125M4.9375 1.4375V11.9375M8.4375 1.4375V6.6875M11.9375 4.9375H1.4375M6.6875 8.4375H1.4375M11.0625 9.3125V12.8125M11.0625 12.8125L9.75 11.5M11.0625 12.8125L12.375 11.5M7.5625 11.0625C7.5625 11.9908 7.93125 12.881 8.58763 13.5374C9.244 14.1938 10.1342 14.5625 11.0625 14.5625C11.9908 14.5625 12.881 14.1938 13.5374 13.5374C14.1938 12.881 14.5625 11.9908 14.5625 11.0625C14.5625 10.1342 14.1938 9.244 13.5374 8.58763C12.881 7.93125 11.9908 7.5625 11.0625 7.5625C10.1342 7.5625 9.244 7.93125 8.58763 8.58763C7.93125 9.244 7.5625 10.1342 7.5625 11.0625Z"
                    stroke="#292929"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            >
              {translate("members-import-instruction-button-download")}
            </ButtonOutline>
          </div>
        </div>
        <div>
          <div className="flex gap-2">
            <BulletNumber>2</BulletNumber>

            <div>
              {" "}
              <h4 className="font-normal text-sm text-neutral-900">
                {translate("members-import-instruction-title-step2")}
              </h4>
              <p className="font-normal text-sm text-neutral-700">
                {translate("members-import-instruction-description-step2")}
              </p>
            </div>
          </div>
          <div className="mt-4">
            <Table>
              <TableHead>
                <TableRow data-type="head">
                  {INSTRUCTION_DATA.head.map((item, key) => (
                    <TableCellHead
                      key={key}
                      className="border-[1px] border-tertiary-200"
                    >
                      {translate(item)}
                    </TableCellHead>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {INSTRUCTION_DATA.body.slice(1).map((item, key) => (
                  <TableRow key={key}>
                    <TableCellBody className="border-[1px] ">
                      <div className="flex gap-2 items-center px-4 py-3 ">
                        {translate(item.column)}
                        <div
                          className={cn(
                            " px-4 py-1 rounded",
                            item.require ? "bg-warning-200" : "bg-tertiary-200",
                          )}
                        >
                          {item.require
                            ? translate("members-import-instruction-require")
                            : translate("members-import-instruction-optional")}
                        </div>
                      </div>
                    </TableCellBody>
                    <TableCellBody className="border-[1px] border-tertiary-200">
                      {translate(item.example)}
                    </TableCellBody>
                    <TableCellBody className="border-[1px] border-tertiary-200">
                      {translate(item.description)}

                      {translate(item.alert) !== "" && (
                        <div className="flex gap-2 bg-warning-200 rounded p-1 items-center mt-2">
                          <div className="w-4 h-4 min-w-4">
                            <AlertCircleIcon />
                          </div>
                          <p className="text-xs font-semibold">
                            {" "}
                            {translate(item.alert)}
                          </p>
                        </div>
                      )}
                    </TableCellBody>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
