import { Modal, ProgressBarStriped } from "@woba/ds-web";
import * as S from "./modal-loading.styles";

export const ModalLoading = ({
  open,
  description,
  title,
}: {
  open: boolean;
  description: string;
  title: string;
}) => {
  return (
    <Modal
      open={open}
      trigger={<></>}
      maxWidth="md"
      title={
        <S.ModalLoadingTitle>
          <h2>{title}</h2>
          <p>{description}</p>
        </S.ModalLoadingTitle>
      }
      css={{ padding: "2rem" }}
      closeIcon={false}
    >
      <S.WrapperStriped>
        <ProgressBarStriped
          color="complementary01-500"
          colorStrip="complementary01-200"
        />
      </S.WrapperStriped>
    </Modal>
  );
};
