import { useBreakpoint } from "@/hooks/use-breakpoint";
import { FunctionComponent } from "react";
import { VisibleBreakpointProps } from "./visible-breakpoint.types";

export const VisibleBreakpoint: FunctionComponent<VisibleBreakpointProps> = ({
  children,
  breakpoint,
  type,
}) => {
  const { visible } = useBreakpoint({ breakpoint, type });

  return <>{visible ? children : null}</>;
};
