import { InstructionSection } from "../layouts/import/instruction-section";
import { ReviewSection } from "../layouts/import/review-section";
import { UploadSection } from "../layouts/import/upload-section";

export const IMPORT_STEPS = {
  instruction: {
    id: 1,
    component: <InstructionSection />,
  },
  upload: {
    id: 2,
    component: <UploadSection />,
  },
  review: {
    id: 3,
    component: <ReviewSection />,
  },
};
