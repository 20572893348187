import { styled } from "@woba/ds-web";
import { Link } from "react-router-dom";

const ItemSelected = {
  backgroundColor: "$complementary01-500",

  "&:before": {
    content: "",
    background: "$neutral-900",
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "4.77px",
  },
};

const variants = {
  status: {
    active: ItemSelected,
  },
};

export const NavItem = styled("li", {
  display: "flex",
  alignItems: "center",

  listStyle: "none",
  width: "100%",

  height: "100%",
});

export const NavLink = styled(Link, {
  position: "relative",
  maxHeight: "2.5rem",
  minHeight: "2.5rem",
  padding: "$space-02 $space-05",

  cursor: "pointer",
  textDecoration: "none",
  color: "$neutral-900",

  flex: 1,

  display: "flex",
  alignItems: "center",

  borderRadius: "0 $sm $sm 0",
  transition: "all 0.4s linear ",

  variants,

  "&:hover": ItemSelected,
});
export const NavIcon = styled("span", {
  height: "0.875rem",
  width: "0.875rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "$space-04",
});
export const NavText = styled("span", {
  whiteSpace: "nowrap",
});
