import {
  DemoRequestInterceptor,
  DemoResponseInterceptor,
} from "@/features/demo";
import { BLOCKED_LEAGUES } from "@/hooks/use-feature-flag/shared/use-league-not-permission";
import { isEnabledByEnvAndLeague } from "@/hooks/use-feature-flag/utils";
import { cookieManager } from "@/utils/cookie-manager";
import { InterceptorStatusCode } from "@/utils/interceptor-status-code";
import axios, { AxiosRequestConfig } from "axios";

const actualDate = new Date();
const expirationDate = new Date(
  new Date(actualDate).setMonth(actualDate.getMonth() + 1),
);

export const wobaBackendApi = axios.create({
  baseURL: import.meta.env.VITE_WOBA_BACKEND_API_URL,
});

export const refreshUserToken = async (config: AxiosRequestConfig) => {
  const userRefreshToken = cookieManager.get<string>({
    cookieName: "u_refresh_token",
  });

  const { data: { token, refreshToken } = {} } = await wobaBackendApi.post(
    "auth/refresh-token",
    {
      refreshToken: userRefreshToken,
    },
  );

  if (token && refreshToken) {
    cookieManager.set({
      cookieName: "u_token",
      value: token,
      globally: true,
      expirationDate,
    });

    cookieManager.set({
      cookieName: "u_refresh_token",
      value: refreshToken,
      globally: true,
      expirationDate,
    });

    config.headers = {
      Authorization: `Bearer ${token}`,
      "auth-token": token,
    };
  }

  return config;
};

wobaBackendApi.interceptors.request.use((config) => {
  const userToken = cookieManager.get<string>({ cookieName: "u_token" });
  const urlParams = new URLSearchParams(window.location.search);

  const queryToken = urlParams.get("auth_token");

  if (queryToken) {
    cookieManager.set({
      cookieName: "u_token",
      value: queryToken,
      globally: true,
      expirationDate,
    });
  }

  if (!userToken && !queryToken) {
    cookieManager.set({
      cookieName: "last_url_path",
      value: "my-privateoffice",
      globally: true,
      expirationDate,
    });

    if (window.location.pathname.includes("insights")) {
      const pathname = window.location.pathname;
      const search = window.location.search;

      cookieManager.set({
        cookieName: "last_url_page",
        value: `${pathname}${search}`,
        globally: true,
        expirationDate,
      });
    }

    window.location.href = `${import.meta.env.VITE_PORTAL_URL}/signin`;

    throw new axios.Cancel("user not logged");
  }
  // @ts-ignore
  config.headers = {
    Authorization: `Bearer ${userToken ? userToken : queryToken}`,
  };
  return config;
});

wobaBackendApi.interceptors.response.use(async (response) => {
  const isUserFromBlockedLeague = isEnabledByEnvAndLeague(
    response.data.user?.leagues_id[0],
    BLOCKED_LEAGUES,
  );

  if (isUserFromBlockedLeague) {
    window.location.href = import.meta.env.VITE_PORTAL_URL;
    throw new axios.Cancel("user not authorized");
  }

  const userRefreshToken = cookieManager.get<string>({
    cookieName: "u_refresh_token",
  });

  const originalRequest = response?.config;

  if (response && response.status === 401 && userRefreshToken) {
    const newRequest = await refreshUserToken(originalRequest);
    return wobaBackendApi(newRequest);
  }

  return response;
});

InterceptorStatusCode(wobaBackendApi);

DemoRequestInterceptor(wobaBackendApi);
DemoResponseInterceptor(wobaBackendApi);
