import { useAuth } from "@/features/authentication";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { FunctionComponent, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  generateTrackData,
  sendTrackData,
  sendUserIdentifyData,
} from "../../utils/track-data";
import { AnalyticsContextProps, AnalyticsProviderProps } from "./segment.types";

const AnalyticsContext = createContext({} as AnalyticsContextProps);

export const AnalyticsProvider: FunctionComponent<AnalyticsProviderProps> = ({
  children,
}) => {
  const { userInfo } = useAuth();
  const { pathname } = useLocation();
  const { isMobile } = useIsMobile();

  const track: AnalyticsContextProps["track"] = (eventName, data) => {
    const trackData = generateTrackData({
      ...data,
      userId: userInfo.user.id,
      value: {
        ...data?.value,
      },
      urlPage: pathname,
      origin: "myoffice",
    });

    sendUserIdentifyData(userInfo);

    sendTrackData({ eventName, ...trackData });
  };

  return (
    <AnalyticsContext.Provider
      value={{
        track,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
