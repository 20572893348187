import { getUsersApi } from "../../adapters/get-users";
import { UseGetUsersTotalProps } from "../../adapters/get-users/get-users.types";

export const useGetTotalUsers = ({
  league,
  groupId,
}: UseGetUsersTotalProps) => {
  const { total } = getUsersApi({
    league,
    groupId,
    limit: 1,
    page: 1,
  });

  return total;
};
