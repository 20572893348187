export const OPTIONS_IMPORT = {
  new: {
    title: "members-import-step-3-card-new-title",
    description: "members-import-step-3-card-new-description",
    alert: "members-import-step-3-card-new-alert",
  },
  update: {
    title: "members-import-step-3-card-update-title",
    description: "members-import-step-3-card-update-description",
    alert: "members-import-step-3-card-update-alert",
  },
};
