import { wobaBackendApi } from "@/services/woba-api";
import { useCallback } from "react";
import useSWR from "swr";
import { UseWobaBackendApiProps } from "./use-woba-backend-api.types";

export const useWobaBackendApi = ({
  method,
  path,
  body,
  service = wobaBackendApi,
}: UseWobaBackendApiProps) => {
  const fetcher = useCallback(
    (url: string) => service[method](url, body).then((res) => res?.data),
    [],
  );

  const { data, mutate, error } = useSWR(path, fetcher, {
    suspense: true,
  });

  return { data, mutate, isLoading: !data && !error };
};
