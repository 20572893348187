import { useDownloadImportMembers } from "@/features/members/hooks/use-download/invalid-import-members/use-download-import-members";
import { CsvDownloadIcon } from "@woba/ds-core/icons";
import { ImportDownloadButtonProps } from "./import-download-button.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const ImportDownloadButton: FunctionComponent<
  ImportDownloadButtonProps
> = ({ data, type, label }) => {
  const { handleExport } = useDownloadImportMembers();
  const [translate] = useTranslation(["members"]);

  return (
    <button
      onClick={() => {
        handleExport(data, type);
      }}
      className="px-1.5 py-1 bg-white rounded border border-gray-200 justify-center items-center gap-2 inline-flex text-zinc-800 text-xs font-medium font-['FK Grotesk'] leading-[14px] min-w-[190px]"
    >
      <CsvDownloadIcon className="w-3.5 h-3.5 relative" />
      {translate(label)}
    </button>
  );
};
