import { styled } from "@woba/ds-web";
import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";

export const DropdownItem = styled(DropdownPrimitives.Item, {
  all: "unset",
  cursor: "pointer",
  padding: "$space-03 $space-04",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  gap: "$space-03",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "20px",
  color: "$neutral-900",
  backgroundColor: "white",

  svg: {
    fill: "$neutral-900",
    width: 24,
  },

  button: {
    all: "unset",
  },

  "&:not(:last-child)": {
    borderBottomStyle: "solid",
    borderWidth: "$border-01",
    borderColor: "$neutral-300",
  },

  "&[data-highlighted]": {
    backgroundColor: "$primary-100",
  },
});
