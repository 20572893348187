import axios from "axios";

export const apiHubspot = axios.create({
  baseURL: import.meta.env.VITE_API_HUBSPOT_URL,
});

export const apiHubspotConversation = axios.create({
  baseURL: import.meta.env.VITE_API_HUBSPOT_CONVERSATION,
});

apiHubspotConversation.interceptors.request.use((config) => {
  const token = import.meta.env.VITE_PORTAL_URL;
  // @ts-ignore
  config.headers = {
    Authorization: `Bearer ${token}`,
  };
  return config;
});
