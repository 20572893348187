import { styled } from "@woba/ds-web";
import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";

export const DropdownPortal = styled(DropdownPrimitives.Portal);

export const DropdownContentWrapper = styled(DropdownPrimitives.Content, {
  borderStyle: "solid",
  borderWidth: "$border-01",
  borderColor: "$neutral-400",
  borderRadius: "$sm",
  backgroundColor: "white",
  width: "inherit",
  marginTop: "$space-02",
});
