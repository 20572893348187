// @ts-nocheck

import { IdentifyClarityProps } from "./clarity.types";

const initialize = () => {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", import.meta.env.VITE_CLARITY_ID);
};

const identifyClarity: IdentifyClarityProps = ({
  userId,
  email,
}: {
  userId: string;
  email: string;
}) => {
  if (window.clarity) {
    window.clarity("set", "user_id", userId);
    window.clarity("set", "email", email);
  } else {
    console.error("Clarity is not loaded yet");
  }
};

export const clarity = { initialize, identifyClarity };
