import { ROUTE } from "@/constants/routes";
import { EVENT_BY_ROUTE, EVENT_CATEGORIES } from "../../constants/events";
import { useAnalytics } from "../../contexts/segment";

export const useChangeRouteTrack = () => {
  const { track } = useAnalytics();
  const onChangeRoute = (path: ROUTE) => {
    if (EVENT_BY_ROUTE[path]) {
      track(EVENT_BY_ROUTE[path]!, {
        category: EVENT_CATEGORIES.MENU,
      });
    }
  };

  return { onChangeRoute };
};
