import { getDefaultTheme } from "@woba/ds-core";
import { useEffect, useState } from "react";
import { useBreakpointsProps } from "./use-breakpoint.types";

export const useBreakpoint = ({ breakpoint, type }: useBreakpointsProps) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [visible, setVisible] = useState<boolean>(false);

  const limit = getDefaultTheme().breaks[breakpoint];

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);

    setVisible(type === "max" ? width <= limit : width > limit);
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [window.innerWidth]);

  return { visible };
};
