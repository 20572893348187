import { styled } from "@woba/ds-web";
import { Link } from "react-router-dom";

export const Tab = styled("button", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "$space-02 $space-03",
  borderRadius: 0,
  borderStyle: "none",
  cursor: "pointer",
  backgroundColor: "white",
  width: "100%",
  minWidth: "3rem",

  "&:active": {
    backgroundColor: "$neutral-300",
  },

  variants: {
    status: {
      active: {
        backgroundColor: "$neutral-200",
      },
    },
  },
});

export const IconWrapper = styled("div", {
  width: 20,
  height: 20,
});

export const LabelWrapper = styled("span", {
  fontWeight: 500,
  fontSize: 10,
  lineHeight: "16px",
});

export const NavLink = styled(Link, {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
  color: "$neutral-900",
});
