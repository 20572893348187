import { styled } from "@woba/ds-web";

export const ContainerCardTeam = styled("div", {
  padding: "$space-03 $space-04",
  minHeight: "3.125rem",

  borderWidth: "$border-01",
  borderColor: "$neutral-300",
  borderStyle: "solid",
  borderRadius: "$sm",

  display: "flex",
  alignItems: "center",
});
export const ContentCardTeam = styled("div", {
  display: "flex",
  alignItems: "center",
  height: "100%",

  span: {
    fontSize: "$md",
    marginLeft: "$space-03",
  },
});
export const Image = styled("div", {
  img: {
    maxWidth: "2rem",
    height: "2rem",
  },
});
