import { ButtonOutline } from "@/components/inputs/button-outline";
import { cn } from "@/utils/utils";
import { CloseIcon } from "@woba/ds-core/icons";
import { Modal } from "@woba/ds-web";
import { useTranslation } from "react-i18next";

export const ModalErrorRequest = () => {
  const [translate] = useTranslation(["core"]);

  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <Modal
      trigger={<></>}
      open={true}
      title={<></>}
      description={<></>}
      css={{
        padding: 0,
        width: "100%",
        maxWidth: 488,
      }}
      maxWidth={"md"}
      closeIcon={false}
    >
      <div className="p-6 text-center flex items-center justify-center flex-col">
        <div
          className={cn(
            "w-16 h-16 rounded-full  flex items-center justify-center mb-4 bg-danger-200 border-4 border-danger-700",
          )}
        >
          <CloseIcon className="w-6 h-6 text-center text-danger-700" />
        </div>
        <h2 className="text-[32px] font-bold text-neutral-900">
          {translate("modal-error-request-title")}
        </h2>
        <p className="py-6 text-base  text-neutral-700">
          {translate("modal-error-request-description")}
        </p>
      </div>
      <div className="p-6 flex flex-col gap-[6px] justify-center items-center w-full">
        <ButtonOutline className="!w-full" onClick={() => handleReloadPage()}>
          {translate("modal-error-request-button-reload")}
        </ButtonOutline>
        <ButtonOutline
          className="!w-full !bg-complementary-500 !text-neutral-900 !border !border-neutral-900"
          onClick={() => handleReloadPage()}
        >
          {translate("modal-error-request-button-close")}
        </ButtonOutline>
      </div>
    </Modal>
  );
};
