// @ts-nocheck

import { Identify } from "./hotjar.types";
import { prefix as stylingPrefix } from "@woba/ds-web";

const initialize = () => {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = {
      hjid: Number(import.meta.env.VITE_HOTJAR_ID),
      hjsv: Number(import.meta.env.VITE_HOTJAR_SV),
    };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
};

const identify: Identify = (userId, params) => {
  window.hj("identify", userId, {
    company_name: params.companyName,
    user_name: params.userName,
    user_phone: params.userPhone,
    user_email: params.userEmail,
  });
};

const event = (eventName: string) => window.hj("event", eventName);

const handleRequestAnimationFrame = () => {
  const elements = document.querySelectorAll<HTMLStyleElement>("head style");

  if (!elements.length) return "";

  return Array.from(elements)
    .reduce<string[]>((previousValue, element) => {
      if (element.dataset.hotjarWorkaround) return previousValue;

      if (!element.sheet) return previousValue;

      const rulesString = Array.from(element.sheet.cssRules)
        .reduce<string[]>((previousRuleValue, rule) => {
          if (!rule.cssText.includes(stylingPrefix)) return previousRuleValue;
          return previousRuleValue.concat(rule.cssText);
        }, [])
        .join(" ");

      return previousValue.concat(rulesString);
    }, [])
    .join(" ");
};

export const hotjarWorkaround = () => {
  if (typeof window === "undefined") return;

  const syncStyles = document.createElement("style");
  syncStyles.dataset.hotjarWorkaround = "true";

  document.head.insertBefore(syncStyles, document.head.children[0]);

  const intervalID = window.setInterval(() => {
    const isHotjarRecording = window?.hj?.settings?.record;
    if (!isHotjarRecording) return;

    window.clearInterval(intervalID);

    let lastStyles: string;

    window.setInterval(() => {
      window.requestAnimationFrame(() => {
        const styles = handleRequestAnimationFrame();
        if (styles === lastStyles) return;

        syncStyles.textContent = styles;
        lastStyles = styles;
      });
    }, 500);
  }, 50);
};

export const hotjar = { initialize, identify, event };
