import { UserInfo } from "@/features/authentication/context/auth/auth.types";
import { useDemo } from "./shared/use-demo";
import { useLeagueNotPermission } from "./shared/use-league-not-permission";
import { useReportsHomeOffice } from "./shared/use-reports-home-office";

import { Envinronments } from "@/enums/envinronment";
import { useHandsUp } from "./shared/use-hands-up";
import { useButtonBudget } from "./shared/use-button-budget";

export const useFeatureFlags = (user?: UserInfo) => {
  const league = user?.user?.leagues_id[0] || 0;

  const currentEnvironment = import.meta.env
    .VITE_APP_ENVINRONMENT as Envinronments;

  const { isReportEnabled } = useReportsHomeOffice(league);
  const { isLeagueBlocked } = useLeagueNotPermission(league);
  const { isDemo } = useDemo();
  const { isHandsUpEnabled } = useHandsUp(league, currentEnvironment);
  const { isButtonBudgetEnabled } = useButtonBudget(league, currentEnvironment);

  return {
    isReportEnabled,
    isLeagueBlocked,
    isDemo,
    isHandsUpEnabled,
    isButtonBudgetEnabled,
  };
};
