import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { UploadDropzone } from "./shared/upload-dropzone";

export const UploadSection = () => {
  const [translate] = useTranslation(["members"]);

  const BulletNumber = ({ children }: {} & PropsWithChildren) => {
    return (
      <div className="bg-complementary-500 min-w-6 w-6 h-6 rounded-full flex items-center justify-center text-xs text-neutral-900">
        {children}
      </div>
    );
  };

  return (
    <div className="pt-6">
      <div className="flex gap-4 flex-col">
        <div>
          <div className="flex gap-2">
            <BulletNumber>1</BulletNumber>
            <div>
              <h4 className="font-normal text-sm text-neutral-900">
                {translate("members-import-step-2-modal-title")}
              </h4>
            </div>
          </div>
        </div>
        <div>
          <UploadDropzone />
        </div>
      </div>
    </div>
  );
};
