import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";

const resourceToBackendConfig = resourcesToBackend(
  (language, namespace, callback) => {
    const importByNamespace: { [key: string]: () => Promise<any> } = {
      core: () => import(`../translations/${language}.json`),
      default: () =>
        import(`../features/${namespace}/translations/${language}.json`),
    };

    const importPromise = (
      importByNamespace[namespace] || importByNamespace.default
    )();

    importPromise
      .then((resources) => {
        callback(null, resources.default);
      })
      .catch((error) => {
        callback(error, null);
      });
  },
);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .use(resourceToBackendConfig)
  .init({
    fallbackLng: "pt_BR",
    supportedLngs: ["pt_BR", "es_MX", "en_US"],
    defaultNS: "core",
    ns: ["core"],
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
    debug: false,
  });

export default i18n;
