import { CookieManager } from "./cookie-manager.types";

const removeSubdomains = (url: URL) => {
  const urlParts = url.hostname.split(".");

  return urlParts
    .slice(0)
    .slice(-(urlParts[urlParts.length - 1].match(/br/gm) ? 3 : 2))
    .join(".");
};

const cookies: { [cookieName: string]: string } =
  document.cookie?.split("; ").reduce((previous, current) => {
    const [name, value] = current.split("=");
    return { ...previous, [name]: value };
  }, {}) || [];

export const cookieManager: CookieManager = {
  get: ({ cookieName }) => {
    const value = cookies[cookieName];
    return value as any;
  },
  set: ({ cookieName, value, expirationDate, globally = false }) => {
    let cookie = `${cookieName}=${value};`;

    if (expirationDate) {
      const dateUTCString = expirationDate.toUTCString();
      cookie += ` expires=${dateUTCString};`;
    }

    if (globally) {
      const domain = removeSubdomains(new URL(window.location.href));
      cookie += ` domain=${domain};`;
    }

    document.cookie = cookie;
  },
  remove: ({ cookieName, globally }) => {
    const date = new Date();
    date.setFullYear(2000);

    let cookie = `${cookieName}=; expires=${date.toUTCString()};`;

    if (globally) {
      const domain = removeSubdomains(new URL(window.location.href));
      cookie += ` domain=${domain};`;
    }

    document.cookie = cookie;
  },
};
