import { useEffect, useState } from "react";

import { Envinronments } from "@/enums/envinronment";

const enviroments = [Envinronments.Staging, Envinronments.Development];

export const useHandsUp = (
  league: number,
  currentEnvironment: Envinronments,
) => {
  const [isHandsUpEnabled, setIsHandsUpEnabled] = useState<boolean>(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (!league) setIsHandsUpEnabled(false);

    setIsHandsUpEnabled(() => {
      return enviroments.includes(currentEnvironment);
    });
  }, [league]);

  return { isHandsUpEnabled: false };
};
