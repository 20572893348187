import { ErrorBoundary } from "@/components/error-boundary";
import { FunctionComponent, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { CardHandRaising } from "../card-hand-raising";
import { CardHandRaisingSkeleton } from "../card-hand-raising/card-hand-raising-skeleton";
import { NavigationItem } from "../navigation-item";
import * as S from "./navigation.styles";
import { SidebarNavProps } from "./navigation.types";
import { useActionsTrack } from "@/features/analytics";
import ValidatePermission from "@/components/auth/validate-permission";

export const SidebarNav: FunctionComponent<SidebarNavProps> = ({
  items,
  onChange,
}) => {
  const [translate] = useTranslation(["sidebar", "core"]);
  const { onActionClick } = useActionsTrack();

  const openOfferPrivateOffice = () => {
    onActionClick("SIDEBAR_OFFER_PRIVATEOFFICE", "MENU");
    window.open(import.meta.env.VITE_URL_REQUEST_PRIVATE_OFFICE, "_blank");
  };

  return (
    <S.Nav>
      <ul>
        {items.map((navItem, index) => (
          <ValidatePermission
            permissions={navItem.permissions || ["all"]}
            key={index}
          >
            <NavigationItem
              name={translate(navItem.name, { ns: "core" })}
              key={`${navItem.id}-${index}`}
              id={navItem.id}
              link={navItem.link}
              icon={navItem.icon}
              onChange={onChange}
            />
          </ValidatePermission>
        ))}
      </ul>

      <S.HandRaisingContainer>
        <ErrorBoundary fallback={<></>}>
          <Suspense fallback={<CardHandRaisingSkeleton />}>
            <CardHandRaising
              handRaising={true}
              handleHandRaising={() => {
                openOfferPrivateOffice();
              }}
              buttonLabel={translate(
                "sidebar-card-hand-raising-help-text-button",
              )}
              title={translate("sidebar-card-hand-raising-help-text-title")}
              description={translate(
                "sidebar-card-hand-raising-help-text-description",
              )}
            />
          </Suspense>
        </ErrorBoundary>
      </S.HandRaisingContainer>
    </S.Nav>
  );
};
