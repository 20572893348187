import { apiHubspotConversation } from "@/services/hubspot";
import {
  GenerateTokenHubspotProps,
  GenerateTokenHubspotResponse,
} from "./generate-token-hubspot.types";

export const generateTokenHubspotApi = async ({
  company_name,
  email,
  first_name,
  last_name,
  id,
}: GenerateTokenHubspotProps) => {
  const { token }: GenerateTokenHubspotResponse =
    await apiHubspotConversation.post("/visitor-identification/tokens/create", {
      company_name,
      email,
      first_name,
      last_name,
      id,
    });

  return {
    token,
  };
};
