import { styled } from "@woba/ds-web";

export const WrapperStriped = styled("div", {
  padding: "$space-04 0",
});

export const ModalLoadingTitle = styled("div", {
  h2: {
    fontSize: "2rem",
    color: "$neutral-900",
  },
  p: {
    fontSize: "$md",
    color: "$neutral-700",
    margin: "$space-02 0",
  },
});
