import { DEMO_URL } from "./demo-response-by-url";

export const parseDemoRequestUrl = (path: string, method: string) => {
  const withoutQueryParams = path.split("?")[0];

  const pathParts = withoutQueryParams.split("/");

  const parsedDemoUrl = pathParts
    .slice(2)
    .map((part) => {
      const isNumber = part.match(/\d+/g);
      return isNumber ? method : part;
    })
    .join("/");

  return `/${parsedDemoUrl}` as DEMO_URL;
};
