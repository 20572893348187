import { useAuth } from "@/features/authentication";
import React, { FunctionComponent, ReactNode } from "react";

type ValidatePermissionProps = {
  permissions: string[];
  mode?: "and" | "or";
  fallback?: ReactNode;
  children: ReactNode;
};

const ValidatePermission: FunctionComponent<ValidatePermissionProps> = ({
  permissions,
  mode = "or",
  fallback = null,
  children,
}) => {
  const { hasPermission } = useAuth();

  const isAuthorized =
    mode === "and"
      ? permissions.every((permission) => hasPermission(permission))
      : permissions.some((permission) => hasPermission(permission));

  return isAuthorized ? <>{children}</> : <>{fallback}</>;
};

export default ValidatePermission;
