export const INSTRUCTION_DATA = {
  head: [
    "members-import-instruction-table-head-column",
    "members-import-instruction-table-head-example",
    "members-import-instruction-table-head-description",
  ],
  body: [
    {
      column: "members-import-instruction-table-body-registration",
      example: "members-import-instruction-table-body-example-registration",
      description:
        "members-import-instruction-table-body-description-registration",
      require: false,
      alert: "members-import-instruction-table-body-invalid-reason",
      type: "invalid",
    },
    {
      column: "members-import-instruction-table-body-name",
      example: "members-import-instruction-table-body-example-name",
      description: "members-import-instruction-table-body-description-name",
      require: true,
      alert: "members-import-instruction-table-body-alert-name",
    },
    {
      column: "members-import-instruction-table-body-surname",
      example: "members-import-instruction-table-body-example-surname",
      description: "members-import-instruction-table-body-description-surname",
      require: true,
      alert: "members-import-instruction-table-body-alert-surname",
    },
    {
      column: "members-import-instruction-table-body-email",
      example: "members-import-instruction-table-body-example-email",
      description: "members-import-instruction-table-body-description-email",
      require: true,
      alert: "members-import-instruction-table-body-alert-email",
    },
    {
      column: "members-import-instruction-table-body-phone",
      example: "members-import-instruction-table-body-example-phone",
      description: "members-import-instruction-table-body-description-phone",
      require: false,
      alert: "members-import-instruction-table-body-alert-phone",
    },
    {
      column: "members-import-instruction-table-body-date",
      example: "members-import-instruction-table-body-example-date",
      description: "members-import-instruction-table-body-description-date",
      require: false,
      alert: "members-import-instruction-table-body-alert-date",
    },

    {
      column: "members-import-instruction-table-body-office",
      example: "members-import-instruction-table-body-example-office",
      description: "members-import-instruction-table-body-description-office",
      require: false,
      alert: "members-import-instruction-table-body-alert-office",
    },
    {
      column: "members-import-instruction-table-body-cost-center",
      example: "members-import-instruction-table-body-example-cost-center",
      description:
        "members-import-instruction-table-body-description-cost-center",
      require: false,
      alert: "members-import-instruction-table-body-alert-cost-center",
    },
    {
      column: "members-import-instruction-table-body-departament",
      example: "members-import-instruction-table-body-example-departament",
      description:
        "members-import-instruction-table-body-description-departament",
      require: false,
      alert: "members-import-instruction-table-body-alert-departament",
    },
    {
      column: "members-import-instruction-table-body-sub-departament",
      example: "members-import-instruction-table-body-example-sub-departament",
      description:
        "members-import-instruction-table-body-description-sub-departament",
      require: false,
      alert: "members-import-instruction-table-body-alert-sub-departament",
    },
    {
      column: "members-import-instruction-table-body-registration",
      example: "members-import-instruction-table-body-example-registration",
      description:
        "members-import-instruction-table-body-description-registration",
      require: false,
      alert: "members-import-instruction-table-body-alert-registration",
    },
    {
      column: "members-import-instruction-table-body-zipcode",
      example: "members-import-instruction-table-body-example-zipcode",
      description: "members-import-instruction-table-body-description-zipcode",
      require: false,
      alert: "members-import-instruction-table-body-alert-zipcode",
    },
  ],
};
