import i18next from "@/config/i18n";

import CleverTap from "clevertap-web-sdk";
import { IdentityProps } from "./clevertap.types";

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const existingRegistration =
        await navigator.serviceWorker.getRegistration();
      if (existingRegistration) {
        await existingRegistration.unregister();
      }

      const registration = await navigator.serviceWorker.register(
        "/clevertap-sw.js",
        {
          scope: "/",
        },
      );

      await navigator.serviceWorker.ready;

      if (Notification.permission === "default") {
        await Notification.requestPermission();
      }

      return registration;
    } catch (error) {
      console.error("Service Worker registration failed:", error);
    }
  }
};

const initialize = async () => {
  try {
    if (!i18next.isInitialized) {
      await new Promise<void>((resolve) => {
        i18next.on("initialized", () => {
          resolve();
        });
      });
    }

    CleverTap.init(import.meta.env.VITE_CLEVERTAP_ACCOUNT_ID);
    CleverTap.setLogLevel(import.meta.env.VITE_CLEVERTAP_LOG_LEVEL);

    CleverTap.notifications.push({
      titleText: i18next.t("core:clevertap-title"),
      bodyText: i18next.t("core:clevertap-body"),
      okButtonText: i18next.t("core:clevertap-button-allow"),
      rejectButtonText: i18next.t("core:clevertap-button-deny"),
      okButtonColor: "#f28046",
      serviceWorkerPath: "/clevertap-sw.js",
    });

    await registerServiceWorker();
  } catch (error) {
    console.error("Failed to initialize CleverTap:", error);
  }
};

const identity = (userInfo: IdentityProps) => {
  CleverTap.onUserLogin.push({
    Site: {
      Email: userInfo.email,
      Name: userInfo.name,
      Phone: userInfo.phone,
      Identity: userInfo.id.toString(),
      "MSG-push": true,
      Platform: "Web",
      Browser: navigator.userAgent,
    },
  });
};

export const clevertap = { initialize, identity };
