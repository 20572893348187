import { ThemeProvider, ToastProvider } from "@woba/ds-web";
import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ErrorContextProvider } from "./context/error-boundary-context";
import { PageContainer } from "./layouts/page-container";

import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

export const App = () => {
  return (
    <FlagsmithProvider
      options={{
        environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID,
        api: import.meta.env.VITE_FLAGSMITH_API_URL,
      }}
      flagsmith={flagsmith}
    >
      <ThemeProvider>
        <ToastContainer position="top-center" autoClose={2000} />
        <ToastProvider>
          <ErrorContextProvider>
            <PageContainer>
              <Suspense>
                <Outlet />
              </Suspense>
            </PageContainer>
          </ErrorContextProvider>
        </ToastProvider>
      </ThemeProvider>
    </FlagsmithProvider>
  );
};
