import { useFeatureFlags } from "@/hooks/use-feature-flag";
import { FunctionComponent } from "react";

import * as S from "./warning-banner.styles";

export const DemoWarningBanner: FunctionComponent = () => {
  const { isDemo } = useFeatureFlags();
  return (
    <>
      {isDemo && (
        <S.Wrapper>Você está acessando uma demonstração do sistema.</S.Wrapper>
      )}
    </>
  );
};
