import { forwardRef } from "react";

import * as S from "./button-outline.styles";
import { ButtonLabelProps } from "./button-outline.types";
import { cn } from "@/lib/utils";

export const ButtonOutline = forwardRef<
  Partial<HTMLButtonElement>,
  ButtonLabelProps
>(({ children, icon, iconSize, css, className, ...props }, ref) => {
  return (
    <S.Wrapper
      disabled={props?.disabled}
      onClick={() => props.onClick}
      className={cn(
        "flex gap-2 text-xs h-[40px] max-h-[40px] rounded-[4px] border border-[#EBEBEB] px-4 py-2 items-center justify-center font-medium text-[#292929]",
        className,
      )}
      css={css}
    >
      <S.ContentButton>
        {!!icon && (
          <div
            className={`${iconSize ? iconSize : "min-w-[15px] w-[15px]"}  mr-2 flex items-center justify-center text-neutral-700`}
          >
            {icon}
          </div>
        )}
        {/* @ts-ignore */}
        <S.Button {...props} ref={ref}>
          {children}
        </S.Button>
      </S.ContentButton>
    </S.Wrapper>
  );
});
