import { AxiosInstance } from "axios";
import { cookieManager } from "./cookie-manager";

export const InterceptorStatusCode = (api: AxiosInstance) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        cookieManager.remove({ cookieName: "u_token", globally: true });

        window.location.href = `${
          import.meta.env.VITE_PORTAL_URL
        }/login?redirect_url=/profile/my-privateoffice`;
      }
    },
  );
};
