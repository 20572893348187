import { useState, useEffect } from "react";
import { isEnabledByEnvAndLeague } from "../utils";

const leagues = JSON.parse(
  import.meta.env.VITE_ALLOWED_REPORT_LEAGUES,
) as number[];

export const useReportsHomeOffice = (league: number) => {
  const [isReportEnabled, setIsReportEnabled] = useState<boolean>(false);

  useEffect(() => {
    setIsReportEnabled(() => isEnabledByEnvAndLeague(league, leagues));
  }, [league]);

  return { isReportEnabled };
};
