import { FunctionComponent } from "react";
import { DropdownContentProps } from "./dropdown-content.types";

import * as S from "./dropdown-content.styles";

export const DropdownContent: FunctionComponent<DropdownContentProps> = ({
  isFixed = false,
  children,
  width,
}) => {
  const maxWidthCss = isFixed ? width : "auto";

  return (
    <S.DropdownPortal>
      <S.DropdownContentWrapper
        css={{ minWidth: width, maxWidth: maxWidthCss }}
      >
        {children}
      </S.DropdownContentWrapper>
    </S.DropdownPortal>
  );
};
