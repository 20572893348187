import { styled } from "@woba/ds-web";
import { Variants } from "./sidebar.types";

const variants: Variants = {
  position: { fixed: { position: "fixed", top: "66px", bottom: 0 } },
  color: {},
};

export const SideBarContainer = styled("aside", {
  height: "100%",
  backgroundColor: "white",

  borderRight: "1px solid $neutral-400",
  padding: "$space-04",

  variants,
});
