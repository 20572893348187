export enum DEMO_URL {
  V0_AUTH = "/auth",
  V1_USERS_ME = "/users/me",
  V1_USERS = "/users",
  V0_COMPANIES_BOOKINGS = "/companies/get/bookings",
  V0_COMPANIES_REPORTS = "/companies/get/reports",
  V0_COMPANIES_GROUPS = "/companies/get/groups",
  V0_COMPANIES_REVIEWS = "/companies/get/reviews",
  V0_COMPANIES_ID = "/companies/get",
  V0_COMPANIES_MEMBERS_PATCH = "/companies/members/patch",
  V0_COMPANIES_MEMBERS_DELETE = "/companies/members/delete",
  V0_COMPANIES_MEMBERS_POST = "/companies/members",
  V0_CONTRACTS = "/contracts",
  V0_PAYMENTS = "/payments",
  V0_VISITSCHEDULES = "/visitschedules",
}

export const getDemoResponseByUrl = (url: DEMO_URL) => {
  const demoByUrl = {
    [DEMO_URL.V0_AUTH]: import("../mocks/auth.json"),
    [DEMO_URL.V1_USERS_ME]: import("../mocks/users/me.json"),
    [DEMO_URL.V1_USERS]: import("../mocks/users.json"),
    [DEMO_URL.V0_COMPANIES_REPORTS]: import(
      "../mocks/companies/id/reports.json"
    ),
    [DEMO_URL.V0_COMPANIES_GROUPS]: import("../mocks/companies/id/groups.json"),
    [DEMO_URL.V0_COMPANIES_REVIEWS]: import(
      "../mocks/companies/id/reviews.json"
    ),
    [DEMO_URL.V0_COMPANIES_ID]: import("../mocks/companies/id.json"),
    [DEMO_URL.V0_COMPANIES_MEMBERS_PATCH]: {},
    [DEMO_URL.V0_COMPANIES_MEMBERS_DELETE]: {},
    [DEMO_URL.V0_COMPANIES_MEMBERS_POST]: {},
    [DEMO_URL.V0_CONTRACTS]: import("../mocks/contracts.json"),
    [DEMO_URL.V0_COMPANIES_BOOKINGS]: import(
      "../mocks/companies/id/bookings.json"
    ),
    [DEMO_URL.V0_PAYMENTS]: import("../mocks/payments.json"),
    [DEMO_URL.V0_VISITSCHEDULES]: import("../mocks/visitschedules.json"),
  };

  return demoByUrl[url];
};
