import { FunctionComponent } from "react";

import * as S from "./card-hand-raising.styles";
import {
  CardHandRaisingProps,
  SoonContactProps,
} from "./card-hand-raising.types";
import { Button } from "@woba/ds-web";
import { CheckCircleIcon } from "@woba/ds-core/icons";
import { useHandRaising } from "@/features/sidebar/hooks/use-hand-raising";
import { useTranslation } from "react-i18next";

const SoonContact: FunctionComponent<SoonContactProps> = ({
  handRaising,
  handleHandRaising,
  buttonLabel,
  titleLabel,
  helpTextLabel,
}) => {
  return handRaising ? (
    <S.ContainerButtonHand>
      <Button onClick={() => handleHandRaising()} variant={"primary"}>
        {buttonLabel}
      </Button>
    </S.ContainerButtonHand>
  ) : (
    <S.ContainerSoonContact>
      <S.BulletSoonContact data-test-id="soon-contact-bullet">
        <CheckCircleIcon />
        {titleLabel}
      </S.BulletSoonContact>
      <Button variant={"link"}>{helpTextLabel}</Button>
    </S.ContainerSoonContact>
  );
};

export const CardHandRaising: FunctionComponent<CardHandRaisingProps> = ({
  handRaising,
  handleHandRaising,
  buttonLabel,
  title,
  description,
}) => {
  const [translate] = useTranslation(["sidebar"]);

  return (
    <S.ContainerCardHandRaising data-test-id="card-hand-raising">
      <h1>{title}</h1>
      <p>{description}</p>
      <SoonContact
        handRaising={handRaising}
        handleHandRaising={handleHandRaising}
        buttonLabel={buttonLabel}
      />
    </S.ContainerCardHandRaising>
  );
};
