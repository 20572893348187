import "@/config/clarity";
import "@/config/hotjar";
import "@/config/hubspot";
import i18n from "@/config/i18n";
import "@/config/open-telemetry";
import "@/config/sentry";
import "@/config/user-guiding";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import "./global.css";
import { Router } from "./router";

import "@woba/ds-core/fonts/FKDisplay/stylesheet.css";
import "@woba/ds-core/fonts/FKGrotesk/stylesheet.css";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </StrictMode>,
);
