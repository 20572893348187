import { EVENT_CATEGORIES, EVENTS } from "../../constants/events";
import { useAnalytics } from "../../contexts/segment";

export const useActionsTrack = () => {
  const { track } = useAnalytics();

  const onActionClick = (
    event: keyof typeof EVENTS,
    category: keyof typeof EVENT_CATEGORIES,
    data?: object,
  ) => {
    if (data) {
      return track(EVENTS[event], {
        category: EVENT_CATEGORIES[category],
        value: { ...data },
      });
    }
    return track(EVENTS[event], {
      category: EVENT_CATEGORIES[category],
    });
  };

  const onActionView = (event: keyof typeof EVENTS) => {
    track(EVENTS[event], { category: EVENT_CATEGORIES.VIEW });
  };

  return { onActionClick, onActionView };
};
