export const tabOptionsData = {
  new: {
    title: "members-tab-options-data-new-title",
    description: "members-tab-options-data-new-description",
  },
  exist: {
    title: "members-tab-options-data-update-title",
    description: "members-tab-options-data-update-description",
  },
  invalid: {
    title: "members-tab-options-data-invalid-title",
    description: "members-tab-options-data-invalid-description",
  },
  delete: {
    title: "members-tab-options-data-remove-title",
    description: "members-tab-options-data-remove-description",
  },
};
