import { useTheme } from "@woba/ds-web";
import { FunctionComponent, PropsWithChildren } from "react";
import SkeletonLib, {
  SkeletonProps,
  SkeletonTheme,
} from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

const SkeletonWrapper: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { theme } = useTheme();
  return (
    <SkeletonTheme
      baseColor={theme.colors?.["neutral-200"]}
      highlightColor={theme.colors?.["neutral-100"]}
      borderRadius={theme.radii?.sm}
    >
      {children}
    </SkeletonTheme>
  );
};

export const SkeletonRect: FunctionComponent<
  Pick<SkeletonProps, "width" | "height">
> = ({ width = "100%", height = "100%" }) => {
  return (
    <div style={{ width, height }}>
      <SkeletonLib
        wrapper={({ children }) => (
          <SkeletonWrapper>{children}</SkeletonWrapper>
        )}
        width={width}
        height={height}
      />
    </div>
  );
};

export const SkeletonCircle: FunctionComponent<
  Pick<SkeletonProps, "width" | "height">
> = ({ width = "100%", height = "100%" }) => {
  return (
    <div style={{ width, height }}>
      <SkeletonLib
        circle
        wrapper={({ children }) => (
          <SkeletonWrapper>{children}</SkeletonWrapper>
        )}
        width={width}
        height={height}
      />
    </div>
  );
};
