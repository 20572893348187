export const STATUS_UPLOAD = {
  sucess: {
    feedback: "members-import-step-2-success-feedback",
    action: "members-import-step-2-success-action",
    description: "",
  },
  error: {
    feedback: "members-import-step-2-error-feedback",
    description: "members-import-step-2-error-description",
    action: "",
  },
};
