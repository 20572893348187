/// <reference types="vite-plugin-svgr/client" />

import {
  Children,
  cloneElement,
  createRef,
  FunctionComponent,
  isValidElement,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { DropdownProps } from "./dropdown.types";
import { DropdownContent } from "./shared/dropdown-content";

import { ReactComponent as ChevronIcon } from "@/assets/icons/chevron.svg";
// @TODO substituir quando tiver uma lib de ícones

import * as S from "./dropdown.styles";

export const Dropdown: FunctionComponent<DropdownProps> = ({
  children,
  value,
  icon,
  disabled,
  variant,
  rightIcon,
  onOpenChange,
  isFixed = false,
  ...inputProps
}) => {
  const [width, setWidth] = useState<number>();

  const triggerCallbackRef = useCallback((node: HTMLButtonElement) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const inputRef = createRef<HTMLInputElement>();

  const onSelectOption: MouseEventHandler<HTMLOptionElement> = ({
    currentTarget,
  }) => {
    inputRef.current?.setAttribute("value", currentTarget.value);
  };

  const childrenWithRewritedProps = Children.map(children, (child) => {
    if (!isValidElement(child)) return;
    return cloneElement(child as ReactElement<any>, {
      onSelect: (event: any) => {
        onSelectOption(event);
        child.props.onSelect?.(event);
      },
    });
  });

  const controlledInputProps = value ? { readOnly: true, value } : {};

  return (
    <S.DropdownWrapper onOpenChange={onOpenChange}>
      <S.DropdownTrigger
        variant={variant}
        disabled={disabled}
        ref={triggerCallbackRef}
      >
        {icon}
        <input
          {...inputProps}
          {...controlledInputProps}
          ref={inputRef}
          disabled={disabled}
          tabIndex={-1}
        />
        {rightIcon}
        <ChevronIcon />
      </S.DropdownTrigger>
      <DropdownContent width={width} isFixed={isFixed}>
        {childrenWithRewritedProps}
      </DropdownContent>
    </S.DropdownWrapper>
  );
};
