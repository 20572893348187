import { styled } from "@woba/ds-web";

export const Nav = styled("nav", {
  position: "relative",
  width: "100%",
  margin: "$space-05 0",
});

export const HandRaisingContainer = styled("div", {
  borderTopWidth: "$border-01",
  borderTopStyle: "solid",
  borderTopColor: "$neutral-400",

  padding: "$space-07 0",
  margin: "$space-07 0",
});
