import { lazy } from "react";
import "@/config/clevertap";
import { BrowserRouter, Navigate, Route } from "react-router-dom";
import { App } from "./App";
import { DEFAULT_ROUTES, DEMO_ROUTES } from "./constants/routes";

import { ImportProvider } from "./features/members/contexts/import-context";
import { SentryRoutes } from "./lib/sentry";

const DashboardPage = lazy(() => import("@/pages/dashboard"));
const ContractsPage = lazy(() => import("@/pages/contracts"));
const PaymentsContractPage = lazy(() => import("@/pages/contracts/payments"));
const HomeOfficeReportPage = lazy(() => import("@/pages/home-office-report"));
const MembersPage = lazy(() => import("@/pages/members"));
const GroupDetailsPage = lazy(() => import("@/pages/members/group-details"));
const ImportMembersPage = lazy(() => import("@/pages/members/import-members"));
const ReviewsPage = lazy(() => import("@/pages/reviews"));
const InsightsPage = lazy(() => import("@/pages/insights"));
const PrivateOfficesPage = lazy(() => import("@/pages/private-offices"));
const PrivateOfficesSchedulingsPage = lazy(
  () => import("@/pages/private-offices/schedulings"),
);
const PackagesPage = lazy(() => import("@/pages/packages"));
const PackageDetailsPage = lazy(() => import("@/pages/packages/details"));
const PaymentsPage = lazy(() => import("@/pages/payments"));

export function Router() {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path={DEFAULT_ROUTES.INDEX} element={<App />}>
          <Route index element={<Navigate to={DEFAULT_ROUTES.DASHBOARD} />} />
          <Route path={DEFAULT_ROUTES.DASHBOARD} element={<DashboardPage />} />
          <Route
            path={DEFAULT_ROUTES.REPORTS}
            element={<HomeOfficeReportPage />}
          />
          <Route path={DEFAULT_ROUTES.MEMBERS} element={<MembersPage />} />
          <Route
            path={DEFAULT_ROUTES.GROUP_DETAILS}
            element={<GroupDetailsPage />}
          />
          <Route
            path={DEFAULT_ROUTES.MEMBERS_IMPORT}
            element={
              <ImportProvider>
                <ImportMembersPage />
              </ImportProvider>
            }
          />
          <Route path={DEFAULT_ROUTES.PAYMENTS} element={<PaymentsPage />} />
          <Route path={DEFAULT_ROUTES.CONTRACTS} element={<ContractsPage />} />
          <Route
            path={DEFAULT_ROUTES.PAYMENTS}
            element={<PaymentsContractPage />}
          />
          <Route path={DEFAULT_ROUTES.REVIEWS} element={<ReviewsPage />} />
          <Route
            path={DEFAULT_ROUTES.BOOKINGS_REPORT}
            element={<Navigate to={DEFAULT_ROUTES.INSIGHTS} />}
          />
          <Route path={DEFAULT_ROUTES.INSIGHTS} element={<InsightsPage />} />
          <Route
            path={DEFAULT_ROUTES.PRIVATE_OFFICES}
            element={<PrivateOfficesPage />}
          />
          <Route
            path={DEFAULT_ROUTES.PRIVATE_OFFICES_SCHEDULINGS}
            element={<PrivateOfficesSchedulingsPage />}
          />
          <Route path={DEFAULT_ROUTES.PACKAGES} element={<PackagesPage />} />
          <Route
            path={DEFAULT_ROUTES.PACKAGE}
            element={<PackageDetailsPage />}
          />
        </Route>
        <Route path={DEMO_ROUTES.INDEX} element={<App />}>
          <Route index element={<Navigate to={DEMO_ROUTES.DASHBOARD} />} />
          <Route path={DEMO_ROUTES.DASHBOARD} element={<DashboardPage />} />
          <Route path={DEMO_ROUTES.MEMBERS} element={<MembersPage />} />
          <Route path={DEMO_ROUTES.PAYMENTS} element={<PaymentsPage />} />
          <Route path={DEMO_ROUTES.CONTRACTS} element={<ContractsPage />} />
          <Route
            path={DEMO_ROUTES.PAYMENTS}
            element={<PaymentsContractPage />}
          />
          <Route path={DEMO_ROUTES.REVIEWS} element={<ReviewsPage />} />
          <Route
            path={DEMO_ROUTES.BOOKINGS_REPORT}
            element={<Navigate to={DEMO_ROUTES.INSIGHTS} />}
          />
          <Route path={DEMO_ROUTES.INSIGHTS} element={<InsightsPage />} />
          <Route
            path={DEMO_ROUTES.PRIVATE_OFFICES_SCHEDULINGS}
            element={<PrivateOfficesSchedulingsPage />}
          />
          <Route path={DEMO_ROUTES.PACKAGES} element={<PackagesPage />} />
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
}
