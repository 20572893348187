import { ErrorContext } from "@/context/error-boundary-context";
import { Component } from "react";
import { ErrorBoundaryProps, ErrorBoundaryState } from "./error-boundary.types";

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static contextType = ErrorContext;

  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      if (!this.props.fallback) {
        return <h1>error</h1>;
      }
      return this.props.fallback;
    }

    return this.props.children;
  }
}
