import { FunctionComponent } from "react";

import * as S from "./card-team.styles";
import { CardTeamProps } from "./card-team.types";

export const CardTeam: FunctionComponent<CardTeamProps> = ({ image, name }) => {
  return (
    <S.ContainerCardTeam>
      <S.ContentCardTeam>
        <S.Image>
          <img src={image} alt={name} />
        </S.Image>
        <span>{name}</span>
      </S.ContentCardTeam>
    </S.ContainerCardTeam>
  );
};
