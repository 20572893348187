import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { TabBarNavigationProps } from "./tab-bar-navigation.types";

import * as S from "./tab-bar-navigation.styles";
import {
  TabBar,
  TabBarItem,
} from "@/components/ds-components/navigation/tab-bar";

import { navItems } from "@/constants/items-menu";
import { useChangeRouteTrack } from "@/features/analytics";
import { ROUTE } from "@/constants/routes";

export const TabBarNavigation: FunctionComponent<
  TabBarNavigationProps
> = () => {
  const { onChangeRoute } = useChangeRouteTrack();
  const [translate] = useTranslation(["core"]);

  return (
    <S.TabBarNavigationContainer>
      <TabBar>
        {navItems.map((item) => (
          <TabBarItem
            key={item.id}
            link={item.link as ROUTE}
            label={translate(item.name)}
            icon={item.icon}
            onChange={onChangeRoute}
          />
        ))}
      </TabBar>
    </S.TabBarNavigationContainer>
  );
};
