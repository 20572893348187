import { DEFAULT_ROUTES } from "@/constants/routes";
import { useActionsTrack } from "@/features/analytics";
import { useAuth } from "@/features/authentication";
import { STATUS_UPLOAD } from "@/features/members/constants/status-upload";
import { useImport } from "@/features/members/contexts/import-context";
import { ModalLoading } from "@/layouts/modal-loading";
import { wobaBackendApi } from "@/services/woba-api";
import { cn } from "@/utils/utils";
import { ExportIcon } from "@woba/ds-core/icons";
import { useRef, useTransition } from "react";
import { useTranslation } from "react-i18next";

export const UploadDropzone = () => {
  const fileInputField = useRef(null);
  const { userInfo } = useAuth();

  const { changeStatus, succesFile, setImportData, setFile, file } =
    useImport();
  const [isPending, startTransition] = useTransition();

  const sendFile = async (formData: FormData) => {
    changeStatus(null);

    try {
      const response = await wobaBackendApi.post<{
        companyId: number;
        productId: string;
        subscribersErrors: any[];
        usersToDelete: any[];
        usersToUpdate: any[];
        subscribersToInsert: any[];
        subscribersToUpdate: any[];
        subscribersToDelete: any[];
      }>(
        `company-subscribers/${userInfo?.league?.id}/process-file?processDelete=true`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setImportData(response.data);
      changeStatus(true);
      return { status: true };
    } catch (error) {
      changeStatus(false);

      return { status: false };
    }
  };
  const { onActionClick } = useActionsTrack();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileEvent = event.target.files?.[0];
    if (fileEvent && fileEvent.name.endsWith(".xlsx")) {
      const formData = new FormData();
      formData.append("file", fileEvent);

      // @ts-ignore TODO:// Using transition to show loading modal
      startTransition(async () => {
        const response = await sendFile(formData);

        if (response) {
          onActionClick("MEMBERS_IMPORT_FILE", "MEMBERS", {
            url_page: DEFAULT_ROUTES.MEMBERS_IMPORT,
            type: response.status ? "sucesso" : "erro",
          });
        }
        setFile(fileEvent);
      });
    }
  };

  const [translate] = useTranslation(["members"]);

  return (
    <label
      htmlFor="dropzone-file"
      className={cn(
        "border border-dashed rounded p-12 flex items-center justify-center border-neutral-600 flex-col relative hover:bg-neutral-100 transition-all cursor-pointer text-neutral-900",
        succesFile
          ? "border-green-500 bg-green-50 text-green-900"
          : succesFile === false
            ? "border-warning-800 bg-warning-100 text-warning-800"
            : "",
      )}
    >
      <p className=" text-sm font-medium">
        {succesFile
          ? `${file?.name} ${translate(STATUS_UPLOAD.sucess.feedback)}`
          : succesFile === false && translate(STATUS_UPLOAD.error.feedback)}
      </p>
      <p className=" text-sm font-medium">
        {succesFile === false && translate(STATUS_UPLOAD.error.description)}
      </p>

      <ModalLoading
        open={succesFile === null}
        title={translate("members-import-step-2-modal-description")}
        description={translate("members-import-step-2-bullet-title")}
      />

      <span className=" text-sm font-medium flex gap-1">
        <ExportIcon className="h-4 w-4" />
        <input
          id="dropzone-file"
          ref={fileInputField}
          type="file"
          onChange={handleFileChange}
          className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
        />
        {succesFile && translate(STATUS_UPLOAD.sucess.action)}
        <span className="underline">
          {translate("members-import-step-2-choice-file")}
        </span>{" "}
        {translate("members-import-step-2-drag-text")}
      </span>
      <p
        className={cn(
          "text-neutral-700 text-sm",
          succesFile
            ? " text-green-900"
            : succesFile === false
              ? " text-warning-800"
              : "",
        )}
      >
        {translate("members-import-step-2-type-file")}
      </p>
    </label>
  );
};
