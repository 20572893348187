import { useState, useEffect } from "react";

import { Envinronments } from "@/enums/envinronment";

const enviroments = [Envinronments.Development, Envinronments.Staging];

export const useButtonBudget = (
  league: number,
  currentEnvironment: Envinronments,
) => {
  const [isButtonBudgetEnabled, setIsButtonBudgetEnabled] =
    useState<boolean>(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (!league) setIsButtonBudgetEnabled(false);

    setIsButtonBudgetEnabled(() => {
      return enviroments.includes(currentEnvironment);
    });
  }, [league]);

  return { isButtonBudgetEnabled };
};
