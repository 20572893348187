import { Card, styled, Button } from "@woba/ds-web";

export const ContainerCardHandRaising = styled(Card, {
  background: "$neutral-100",
  maxWidth: "242px",
  width: "100%",

  textAlign: "center",

  h1: {
    fontSize: "16px",
  },

  p: {
    fontSize: "14px",
    color: "$neutral-700",
    padding: "$space-03 0 $space-05",
  },

  div: {
    width: "initial",

    div: {
      justifyContent: "center",
    },
  },
});

export const ContainerButtonHand = styled("div", {
  div: {
    div: {
      border: "solid 1px black",
      backgroundColor: "$complementary01-600",

      "&:hover": {
        backgroundColor: "$complementary01-700",
      },

      button: {
        color: "$neutral-900",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },
});

export const ContainerSoonContact = styled("div", {
  display: "flex",
  flexDirection: "column",

  button: {
    color: "$complementary01-800",
    textDecoration: "underline",
    fontSize: "12px",
  },
});

export const BulletSoonContact = styled("div", {
  backgroundColor: "$complementary01-300",
  borderRadius: "$sm",
  padding: "$space-03",
  textAlign: "start",

  fontSize: "12px",
  color: "$complementary01-800",

  display: "flex",

  svg: {
    width: "14px",
    height: "14px",
    color: "$complementary01-800",
    marginRight: "$space-03",
  },
});
